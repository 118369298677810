<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :v-else-if="1"
      loader="dots"
    ></Loading>

    <!-- Filter Modal -->
    <!-- <b-modal
      hide-header-close
      hide-header
      id="filter-modal"
      :cancel-title="$t('btn.clear')"
      @cancel="handleclear"
      :ok-title="$t('btn.conf')"
    >
      <b-row>
        <b-col class="text-end" cols="2"> </b-col>

        <b-col class="text-center" cols="8">
          <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
        </b-col>
        <b-col class="text-end" cols="2">
          <button
            class="close_modal text-light border-none"
            @click="$bvModal.hide('filter-modal')"
          >
            <b-icon-x scale="1.5" />
          </button>
        </b-col>
      </b-row>
      <label>{{ $t("zone.name") }}</label>
      <select class="form-control" v-model="filterOptions.role">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in position"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
      <label>{{ $t("filter.dep") }}</label>
      <select class="form-control" v-model="filterOptions.department">
        <option value="ทั้งหมด">{{ $t("filter.all") }}</option>
        <option
          v-for="(list, index) in department"
          :key="index"
          :value="list.text"
        >
          {{ list.text }}
        </option>
      </select>
    </b-modal> -->

    <!-- navbar -->
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="back-icon close-min1025">
        <img src="../../assets/previous.svg" width="30" @click="gotoHome()" />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>

      <div style="padding: 0.4em">
        <SuccessDialog
          :msg="successMessage"
          v-show="showSuccess"
        ></SuccessDialog>
        <div class="tab-content" id="myTabContent">
          <div class="pt-4 pb-2 gradient-text">
            <h2 class="head-table-text">{{ $t("vendor.name") }}</h2>
          </div>
        </div>
        <div class="mb-4">
          <b-row class="w-100 m-0">
            <b-col
              class="mb-2 mb-md-0 d-flex justify-content-md-start"
              cols="12"
              md="8"
            >
              <button
                class="d-flex filter text-light p-2 m-0"
                style="border: none"
                type="button"
                @click="openModalAddVendor"
              >
                + {{ $t("btn.add_vendor") }}
              </button>
            </b-col>
            <!-- <b-col class="d-flex" cols="10" md="4">
              <span
                class="filter-box p-2 d-flex mr-2"
                id="basic-addon1"
                style="border-radius: 10px 0 0 10px"
              >
                <img src="../../assets/search-white.svg" width="20px" />
              </span>
              <input
                type="text"
                class="form-control filter-box"
                style="
                  border-right-style: none;
                  border-radius: 0 10px 10px 0 !important;
                "
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </b-col> -->
            <!-- <b-col cols="2" md="1" class="d-flex align-items-center justify-content-md-center">
              <button
                class="filter"
                style="border: none"
                type="button"
                id="button-addon2"
                @click="$bvModal.show('filter-modal')"
              >
                <img src="../../assets/filter-white.svg" width="20px" />
              </button>
            </b-col> -->
          </b-row>
        </div>

        <div class="main">
          <!-- Ag Grid Table -->
          <ManageVendorTable :searchText="searchText" ref="userTable" />

          <!-- Modal Delete -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div
                class="modal-content container-fluid pt-4 pb-2"
                style="border-radius: 10px"
              >
                <div class="row">
                  <img class="del-icon" src="../../assets/delete_bin.svg" />
                  <p>
                    {{ $t("vendor.click_todel") }}
                  </p>
                </div>
                <div class="row">
                  <button
                    type="button"
                    class="btn cancle col"
                    data-dismiss="modal"
                  >
                    {{ $t("btn.canc") }}
                  </button>
                  <button
                    type="button"
                    class="confirm-btn col"
                    data-dismiss="modal"
                    @click="confirmDelete()"
                  >
                    {{ $t("btn.conf") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Add -->
        <b-modal
          id="addVendor"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
          centered
        >
          <b-form-group>
            <h3 style="text-align: center">
              {{ $t("vendor.name") }}
            </h3></b-form-group
          >

          <!-- modal add vendor -->
          <form @submit.prevent="addVendor">
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label style="padding: 0 1.2rem;">{{
                    $t("vendor.vendor_name")
                  }}</label>
                  <b-input-group>
                    <b-form-input
                      v-model.trim="name"
                      :placeholder="$t('vendor.vendor_name')"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <label style="padding: 0 1.2rem;">{{
                    $t("vendor.tel")
                  }}</label>
                  <b-input-group>
                    <input
                      class="form-control"
                      type="tel"
                      :placeholder="$t('vendor.tel')"
                      v-model.trim="tel"
                      @input="replaceNumber"
                      required
                    />
                  </b-input-group>
                  <label style="padding: 0 1.2rem;">{{
                    $t("vendor.email")
                  }}</label>
                  <b-input-group>
                    <b-form-input
                      v-model.trim="email"
                      :placeholder="$t('vendor.email')"
                      type="email"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-3 flex justify-content-center text-center"
              >
                <div class="row">
                  <button
                    type="button"
                    class="btn cancle col"
                    data-dismiss="modal"
                    @click="$bvModal.hide('addVendor')"
                  >
                    {{ $t("btn.canc") }}
                  </button>

                  <button
                    type="submit"
                    class="col"
                    data-dismiss="modal"
                    :class="[!addVendorCheck ? 'btn-disabled' : 'confirm']"
                    :disabled="!addVendorCheck"
                  >
                    {{ $t("btn.save") }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </form>
        </b-modal>
        <!-- Modal Add -->
        <b-modal
          id="editVendor"
          title="BootstrapVue"
          hide-header-close
          hide-footer
          hide-header
          centered
        >
          <b-form-group>
            <h3 style="text-align: center">
              {{ $t("vendor.name") }}
            </h3></b-form-group
          >

          <!-- modal edit vendor -->
          <form @submit.prevent="editVendor">
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label style="padding: 0 1.2rem;">{{
                    $t("vendor.vendor_name")
                  }}</label>
                  <b-input-group>
                    <b-form-input
                      v-model.trim="selectedVendor.name"
                      :placeholder="$t('vendor.vendor_name')"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <label style="padding: 0 1.2rem;">{{
                    $t("vendor.tel")
                  }}</label>
                  <b-input-group>
                    <input
                      class="form-control"
                      type="tel"
                      :placeholder="$t('vendor.tel')"
                      v-model.trim="selectedVendor.tel"
                      @input="replaceNumberEdit"
                      required
                    />
                  </b-input-group>
                  <label style="padding: 0 1.2rem;">{{
                    $t("vendor.email")
                  }}</label>
                  <b-input-group>
                    <b-form-input
                      v-model.trim="selectedVendor.email"
                      :placeholder="$t('vendor.email')"
                      type="email"
                      required
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mt-3 flex justify-content-center text-center"
              >
                <div class="row">
                  <button
                    type="button"
                    class="btn cancle col"
                    data-dismiss="modal"
                    @click="$bvModal.hide('editVendor')"
                  >
                    {{ $t("btn.canc") }}
                  </button>

                  <button
                    type="submit"
                    class="col"
                    data-dismiss="modal"
                    :class="[!editVendorCheck ? 'btn-disabled' : 'confirm']"
                    :disabled="!editVendorCheck"
                  >
                    {{ $t("btn.save") }}
                  </button>
                </div>
              </b-col>
            </b-row>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookie from "js-cookie";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import ManageVendorTable from "@/components/CustomTable/ManageVendorTable.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import SuccessDialog from "@/components/SuccessDialog.vue";
import replacePhone from "../../util/replacePhoneNumber";

export default {
  data() {
    return {
      isLoading: true,
      showSuccess: false,
      successMessage: "",
      searchText: "",
      filterOptions: {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      },
      role_choice: [],
      index: "",
      data: {
        id: "",
        name: "",
        departmnet: "",
        role: "",
      },
      name: "",
      tel: "",
      email: "",
      del_vendor_id: null,
      update_vendor_id: null,
      updateVendor: "",
      selectedVendor: {},
    };
  },
  components: {
    AdminSlideMenu,
    AdminMenuBar,
    Loading,
    ManageVendorTable,
    SuccessDialog,
  },
  async mounted() {
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    await this.$store.dispatch("getVendor", id_company);
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      vendor: "vendor",
    }),
    addVendorCheck() {
      return (
        this.name.length > 0 && this.email.length > 0 && this.tel.length > 0
      );
    },
    editVendorCheck() {
      return (
        this.selectedVendor.name?.length > 0 &&
        this.selectedVendor.tel?.length > 0 &&
        this.selectedVendor.email?.length > 0
      );
    },
  },
  methods: {
    replaceNumber() {
      this.tel = replacePhone(this.tel);
    },
    replaceNumberEdit() {
      this.selectedVendor.tel = replacePhone(this.selectedVendor.tel);
    },

    handleclear() {
      this.filterOptions = {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      };
    },
    async openModalAddVendor() {
      await this.$bvModal.show("addVendor");
    },
    gotoHome() {
      this.$router.push("/");
    },
    showDelete(vendor) {
      this.del_vendor_id = vendor.vendor_id;
    },
    async addVendor() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      if (this.addVendorCheck) {
        let data = {
          name: this.name,
          tel: this.tel,
          email: this.email,
          id_company: id_company,
        };
        try {
          await this.$store.dispatch("addVendor", data);
          this.successMessage = this.$t("dialog.add_success");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
        } catch (e) {
          if (e.response) {
            if (e.response.data.message == "Already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_vendor"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.name = "";
        this.email = "";
        this.tel = "";
        this.$store.dispatch("getVendor", id_company);
        await this.$bvModal.hide("addVendor");
        this.isLoading = false;
      }
    },
    async confirmDelete() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      let data = {
        id_company: id_company,
        vendor_id: this.del_vendor_id,
      };
      try {
        await this.$store.dispatch("deleteVendor", data);
        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
      } catch (err) {
        console.log(err);
      }
      this.$store.dispatch("getVendor", id_company);
      await this.$bvModal.hide("addVendor");
      this.isLoading = false;
    },
    async setEditVendor(vendor_id) {
      this.update_vendor_id = vendor_id;
      this.selectedVendor = {
        ...this.vendor.find((vendor) => vendor.vendor_id == vendor_id),
      };
    },
    async editVendor() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.selectedVendor.name = this.selectedVendor.name.trim();
      if (this.selectedVendor.name) {
        let data = {
          name: this.selectedVendor.name,
          tel: this.selectedVendor.tel,
          email: this.selectedVendor.email,
          id_company: id_company,
          vendor_id: this.update_vendor_id,
        };
        try {
          await this.$store.dispatch("updateVendor", data);
          this.successMessage = this.$t("dialog.save_edit");
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false;
          }, 2000);
          this.$bvModal.hide("editVendor");
        } catch (e) {
          if (e.response) {
            if (e.response.data.message == "This name already exists") {
              Swal.fire({
                icon: "error",
                title: this.$t("dialog.error"),
                text: this.$t("dialog.err_vendor"),
              });
            }
          } else {
            console.log(e);
          }
        }
        this.$store.dispatch("getVendor", id_company);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
select {
  border-radius: 10px !important;
}

.position-box {
  background: #fdfdfd;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}
.tag-email {
  display: flex;
  // height: 30px;  font-size: 14px;

  color: #000000;
  background: #c7c7c7;
  width: fit-content;
  border-radius: 15px;

  padding: 0.25em 1.25em 0.25em 1em;
  margin: 0.25em 0.4em;
}
.delete-tag {
  width: 20px !important;
  height: 20px !important;
  font-size: 13px !important;
  border-radius: 50%;
  background: #7e7e7e;
  margin: 0px !important;
  border: none;
  color: #c7c7c7 !important;
  cursor: pointer;
}

.main {
  tfoot {
    border-radius: 20px;
    height: 20px;
    background: transparent linear-gradient(0deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
  }
  tfoot td {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: none;
  }
  thead,
  tbody {
    display: block;
  }
  thead th {
    min-width: calc(80vw / 7);
    max-width: calc(80vw / 7);
  }
  thead th:last-child {
    min-width: calc(80vw / 7 + 16px);
    max-width: calc(80vw / 7 + 16px);
  }
  tbody td {
    min-width: calc(80vw / 7);
    max-width: calc(80vw / 7);
  }
  tbody td:last-child {
    min-width: calc(80vw / 7 + 16px);
    max-width: calc(80vw / 7 + 16px);
  }

  tbody {
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1025px) {
  //  table scrollable
  .main {
    thead,
    tbody {
      display: block;
    }
    thead th {
      min-width: calc(200vw / 7);
      max-width: calc(200vw / 7);
    }
    tbody td {
      min-width: calc(200vw / 7);
      max-width: calc(200vw / 7);
    }
    thead th:last-child {
      min-width: calc(200vw / 7 + 16px);
      max-width: calc(200vw / 7 + 16px);
    }
    tbody td:last-child {
      min-width: calc(200vw / 7 + 16px);
      max-width: calc(200vw / 7 + 16px);
    }

    tbody {
      height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.sub-label {
  padding: 0.4rem;
}
.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img_preview {
  height: 40%;
  width: 40%;
  border-radius: 10px;
  object-fit: cover;
}
.edit-page {
  position: inherit;
  margin: auto;
  padding: auto;
  width: 100%;
}
.btn-edit {
  color: white !important;
  padding: 0 auto !important;
}
.icon-back {
  top: 15px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  // margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}

.sub-menu {
  color: $font-grey;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}
.custom-control custom-radio {
  height: max-content;
  align-self: center;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

#basic-addon1 {
  // border: 1px solid #007AFE;
  border-left-style: none;
  border-right-style: none;
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
// .table:last-child {
//   border-radius: 30px 30px 0 0;
// }

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}

.btn-disabled {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #cecece 0%, #7c7c7c 100%) 0%
    0% no-repeat padding-box;
  cursor: not-allowed !important;
}

.confirm-btn {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #fe0000 0%, #810101 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 320px) and (max-width: 430px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 430px) and (max-width: 1025px) {
  .manageAsset-page {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-form {
    height: fit-content !important;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
// .modal-content {
//   // padding: 1rem;
//   border-radius: 35px;
//   height: auto;

//   & label {
//     color: #4b4b4b;
//     padding: 0.4rem;
//   }
// }

.responsive-bar-admin-menu {
  cursor: pointer;
  span:hover {
    text-decoration: underline;
  }
}

.responsive-bar-admin {
  z-index: 999;
  background: white;
  box-shadow: $shadow-card;
  &-custom {
    display: static;
    grid-template-columns: auto;
    grid-template-rows: auto;
    box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    font-size: 16px;
    background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
      0% no-repeat padding-box;
    & img {
      width: 124px;
      height: 125px;
    }
  }
  &-menu {
    padding: 1rem;
    padding-top: 2rem;
    padding-left: 2rem;
    text-align: left;
  }
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

@media only screen and (max-width: 1024px) {
  .responsive-bar-admin {
    display: none;
    box-shadow: $shadow-default;
  }
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
